.emailform {
  @apply w-full max-w-[584px] mt-6;
  &__input {
    background: linear-gradient(
      39.51deg,
      rgba(0, 0, 0, 0.2) 14.72%,
      rgba(255, 255, 255, 0.2) 94.9%
    );
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: border-color 0.4s;
    @apply sm:text-sm text-base placeholder:text-monsoon text-gainsboro backdrop-blur w-full rounded-full py-5 sm:pl-8 pl-4 pr-32;
    &:focus {
      border-color: rgba(255, 255, 255, 0.8);
      @apply outline-none;
    }
  }
  &__btn {
    @apply leading-6 rounded-full py-5 px-4 sm:text-sm text-base sm:min-w-[145px] min-w-[103px] font-semibold overflow-hidden absolute right-0 inline-flex cursor-pointer top-0 text-center justify-center z-[1] sm:h-16 h-[62px];
    &:before {
      content: "";
      background: linear-gradient(
        100.42deg,
        #ffffff 13.83%,
        #67d8ff 58.52%,
        #95f6d2 91.13%
      );
      @apply absolute -z-[1] -top-full right-0 -bottom-full left-0;
    }
    span {
      @apply text-[#161212];
    }
    &:hover {
      &:before {
        animation: buttonhover 2s linear infinite;
      }
    }
  }
  .is-invalid {
    input {
      border-color: #ff6161;
    }
  }
}
