//import tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

//fonts
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");

//config
@import "~normalize.css/normalize.css";
@import "./keyframes.scss";

//base
@layer base {
  html {
    @apply text-gainsboro bg-black;
    overflow-x: hidden;
  }
  body {
    min-width: 375px;
  }
}
